import React, { useEffect, useState } from 'react';
import notify from 'devextreme/ui/notify';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import ScreenHeader from '../../../components/header/ScreenHeader/ScreenHeader';
import RaioXDashboard from './RaioXDashboard/RaioXDashboard';
import RaioXAdesao from './RaioXAdesao/RaioXAdesao';

import { raioxService } from './RaioX.service';

import { menus } from '../../../shared/constants/MenuConstants';
import { getNomePagina, getDadosLogin} from '../../../shared/utils/Utils';
import { NotificationLevel } from '../../../shared/constants/Const';

import styles from './RaioX.module.scss'


export function RaioX() {
  const PATH_MENU = menus.COD_16999;
  const [isLoading, setIsLoading] = useState(true);
  const [adesaoConcluida, setAdesaoConcluida] = useState(false);
  const [credenciamentoConcluido, setCredenciamentoConcluido] = useState(false);
  const [statusDetalhado, setStatusDetalhado] = useState('');
  const [erroDetalhado1, setErroDetalhado1] = useState('');
  const [erroDetalhado2, setErroDetalhado2] = useState('');

  useEffect(() => {
    getStatusRaioX();
  }, [])

  async function getStatusRaioX(){
    setIsLoading(true);

    const resultAdesao = await raioxService.GetVerificarEmpresaRaioX();
    const resultCredenciamento = await raioxService.GetStatusAdesao();
    const dadosLogin = getDadosLogin();
    let permiteNovoCadastro = false;
    let permiteOverlap = false;
    let overlapRealizado = false;
    let cadastroMensagem = "";

    if (resultAdesao && resultAdesao.notificationLevel === NotificationLevel.Success) {
      const validacaoEmpresaRaiox = resultAdesao.data; 
      permiteNovoCadastro = validacaoEmpresaRaiox.permiteNovoCadastro;
      permiteOverlap = validacaoEmpresaRaiox.permiteOverlap;
      overlapRealizado = validacaoEmpresaRaiox.overlapRealizado;
      
      if (validacaoEmpresaRaiox.mensagem)
        cadastroMensagem = validacaoEmpresaRaiox.mensagem;

      setAdesaoConcluida(!permiteNovoCadastro);

    } else {
      let msgErro = "Sem resposta ao verificar a adesão no Raio-X.";
      if (resultAdesao) {
        msgErro = resultAdesao.message;
        if (['config', 'suporte'].includes(dadosLogin.login.toLowerCase())) {
          setErroDetalhado1(resultAdesao.message);
        }
      }

      notify(
        msgErro,
        'error',
        15000,
        'top'
      );
    }

    if (resultCredenciamento && resultCredenciamento.notificationLevel === NotificationLevel.Success) {
      const statusCredenciamento = resultCredenciamento.data;
      if (['config', 'suporte'].includes(dadosLogin.login.toLowerCase())) {
        setStatusDetalhado((cadastroMensagem ? cadastroMensagem + ' ' : '')
          + statusCredenciamento.statusCredenciamentoDescricao 
          + (permiteNovoCadastro ? ' Permite Novo Cadastro.' : '')
          + (permiteOverlap && !overlapRealizado ? ' Permite Overlap.' : '')
          + (overlapRealizado ? ' Overlap já realizado.' : '') );
      }

      setCredenciamentoConcluido(statusCredenciamento.credenciamentoConcluido);

    } else {
      let msgErro = "Sem resposta ao verificar o credenciamento no Raio-X.";
      if (resultCredenciamento) {
        msgErro = resultCredenciamento.message;
        if (['config', 'suporte'].includes(dadosLogin.login.toLowerCase())) {
          setErroDetalhado2(msgErro);
        }
      }

      notify(
        msgErro,
        'error',
        9000,
        'top'
      );
    }

    setIsLoading(false);
  }

  function Loading() {
    return (
      <div className={styles.loader}>
        <h1>Carregando Informações...</h1>
        <svg className={styles.spinner} viewBox="0 0 50 50">
          <circle
            className={styles.path}
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          ></circle>
        </svg>
      </div>
    )
  }

  return (
    <div>
      <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
        <ScreenHeader search={false} pathMenu={PATH_MENU} newButton={false} />
        { isLoading
          ? <Loading />
          : adesaoConcluida && credenciamentoConcluido
            ? <RaioXDashboard />
            : <RaioXAdesao
                isAdesaoConcluida={adesaoConcluida}
                setIsAdesaoConcluida={setAdesaoConcluida}
                statusDetalhado={statusDetalhado}
                erroDetalhado1={erroDetalhado1}
                erroDetalhado2={erroDetalhado2}
              />
        }
      </LinxPostos>
    </div>
  )
}

import axios from 'axios';
import moment from 'moment';

import { TOKEN_PASS_AXIOS } from '../../../shared/constants/Const';
import { BASE_URL_GATEWAY } from '../../../shared/constants/GatewayUrlConstant';

import { getToken } from '../../../authentication/Auth';
import { formatStringToLowerWithoutSpecialCharacters } from '../../../shared/utils/Utils';

axios.defaults.headers.common['TokenPass'] = TOKEN_PASS_AXIOS;

export const raioxService = {
  GetEmpresa,
  GetVerificarEmpresaRaioX,
  Aderir,
  GetStatusAdesao,
  GetDadosDashboard,
  existeCampoObrigatorioVazio,
  existeCampoObrigatorioEmpresaVazio,
  existeCampoObrigatorioEnderecoVazio,
  existeCampoObrigatorioFuncionarioVazio,
  convertResponseToChartData,
  convertResponseToValorTotal,
  convertResponseToQuantidadeTotal,
  convertResponseToGridData,
};

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Verifica se a empresa fez a adesão ao Raio-x
async function GetVerificarEmpresaRaioX() {
  const response = await urlLinxCore
    .get(`/Raiox/VerificarEmpresa`, {})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

// Recupera o status do credenciamento da adesão ao Raio-X
async function GetStatusAdesao() {
  const response = await urlLinxCore
    .get(`/Raiox/RecuperarStatusRaioX`, {})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

// Recupera a empresa
async function GetEmpresa() {
  const response = await urlLinxCore
    .get(`/Empresa/RecuperarDadosRaioX`, {})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
    console.log(response);
  return response;
}

// Envia dados para realizar a adesão ao Raio-X
async function Aderir() {
  const response = await urlLinxCore.post('/Raiox/Aderir')
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

async function GetDadosDashboard(filters) {
  const response = await urlLinxCore
    .post('/Raiox/ConsultarDashboard', filters)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

function existeCampoObrigatorioVazio(empresa) {
  const todosCamposPreenchidos = !existeCampoObrigatorioEmpresaVazio(empresa)
  && !existeCampoObrigatorioEnderecoVazio(empresa)
  && !existeCampoObrigatorioFuncionarioVazio(empresa);

  if (todosCamposPreenchidos) {
    return false;
  } else {
    return true;
  }
}

function existeCampoObrigatorioEmpresaVazio(empresa) {
  console.log('empresa');
  const todosCamposPreenchidos = empresa.nomeFantasia
  && empresa.razaoSocial
  && empresa.cpfCnpj;

  if (todosCamposPreenchidos) {
    return false;
  } else {
    return true;
  }
}

function existeCampoObrigatorioEnderecoVazio(empresa) {
  const todosCamposPreenchidos = empresa.endereco.logradouro
  && empresa.endereco.bairro
  && empresa.endereco.localidade.descricao
  && empresa.endereco.unidadeFederativa.sigla;

  if (todosCamposPreenchidos) {
    return false;
  } else {
    return true;
  }
}

function existeCampoObrigatorioFuncionarioVazio(empresa) {
  const todosCamposPreenchidos = empresa.operadores 
  && empresa.operadores.length > 0
  && empresa.operadores[0].nome
  && empresa.operadores[0].email
  && empresa.operadores[0].telefone;

  if (todosCamposPreenchidos) {
    return false;
  } else {
    return true;
  }
}

function convertResponseToChartData(dados) {
  moment.locale('pt-br');
  // Formata os valores dos campos Nome e Data
  dados.map(item => (
    item = Object.assign(item, { nomeFormatado: formatStringToLowerWithoutSpecialCharacters(item.nome) })
    ));

  dados.map(item => item.series.map(x => x.data = moment(x.data).format('YYYY-MM-DD')));

  // Cria um objeto que retorna a referencia das cores para cada agrupamento
  var dadosPorCor = JSON.parse(JSON.stringify(dados));
  getChartColors(dadosPorCor);

  // Converte os dados para serem utilizados no Chart
  var dadosPorDiaENome = dados.map(item => (
    item.series.map(serie => {
      if(serie.valor > 0){
      return ({
        data: moment(serie.data).format('DD/MM/YYYY'),
        [item.nomeFormatado]: serie.valor
      });
    }else{ return {} }
  })
  ));

  // allDays = Pega todos os dias e cria listas para estes dias
  var allDaysList = dados.map(obj => obj.series.map(item => (moment(item.data).format('DD/MM/YYYY'))));
  var allDaysConcat = [];
  var allDaysDiff = [];

  // Concatena todas as listas ficando apenas uma lista
  if(allDaysList.length > 1) {
    allDaysList.forEach((item, index) => {
      if(index < (allDaysList.length - 1)){
        if(index === 0){
          allDaysConcat = item;
          allDaysConcat = allDaysConcat.concat(allDaysList[index+1]);
        }else{
          allDaysConcat = allDaysConcat.concat(allDaysList[index+1]);
        }
      }
    });

    // Remove dias duplicados
    allDaysDiff = Array.from(new Set(allDaysConcat));
  } else {
    allDaysDiff = allDaysList[0];
  }

  // Aqui é onde a magia acontece!
  // Neste ponto, os dados são convertidos para serem utilizados no chart.
  var dadosMergedList = [];

  if(dadosPorDiaENome.length > 1){
    allDaysDiff.map(day => {
      var dadosMerged = [];
      var itemDiaENome = dadosPorDiaENome.map(item => item.find(x => x.data === day))
      itemDiaENome.forEach((item, index) => {
        if(index < (itemDiaENome.length - 1)){
          if(index === 0){
            dadosMerged = Object.assign(dadosMerged, item);
            dadosMerged = Object.assign(dadosMerged, itemDiaENome[index+1]);
          }else{
            dadosMerged = Object.assign(dadosMerged, itemDiaENome[index+1]);
          }
        }else{
          dadosMergedList.push({...dadosMerged});
        }
      });
    });
  } else {
    dadosMergedList = dadosPorDiaENome[0];
  }

  return [dadosPorCor, dadosMergedList];
}

function getChartColors(objeto) {
  for(var i=0; i<objeto.length; i++) {
    delete objeto[i].id;
    delete objeto[i].series;
  }

  return objeto;
}

function convertResponseToValorTotal(dados) {
  // Clona os dados para não alterar o objeto principal
  var dadosClone = JSON.parse(JSON.stringify(dados));

  // Pega somente os valores
  var valores = dadosClone.map(item => item.series.map(x => ({valor: x.valor})));

  // Concatena as listas ficando apenas uma com os valores
  var valoresConcat = [];

  if(valores.length == 1){
    valoresConcat = valores[0];
  }else{
    valores.forEach((item, index) => {
      if(index < (valores.length - 1)){
        if(index === 0){
          valoresConcat = item;
          valoresConcat = valoresConcat.concat(valores[index+1]);
        }else{
          valoresConcat = valoresConcat.concat(valores[index+1]);
        }
      }
    });
  }

  // Soma todos valores da lista
  var valorSum = valoresConcat.reduce((a, b) => ({valor: a.valor + b.valor}));

  // Retorna apenas o valor total final
  return (valorSum.valor).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
}

function convertResponseToQuantidadeTotal(dados) {
  // Clona os dados para não alterar o objeto principal
  var dadosClone = JSON.parse(JSON.stringify(dados));

  // Pega somente as quantidades
  var quantidade = dadosClone.map(item => item.series.map(x => ({quantidade: x.quantidade})));

  // Concatena as listas ficando apenas uma com as quantidades
  var quantidadeConcat = [];

  if(quantidade.length == 1){
    quantidadeConcat = quantidade[0];
  }else{
    quantidade.forEach((item, index) => {
      if(index < (quantidade.length - 1)){
        if(index === 0){
          quantidadeConcat = item;
          quantidadeConcat = quantidadeConcat.concat(quantidade[index+1]);
        }else{
          quantidadeConcat = quantidadeConcat.concat(quantidade[index+1]);
        }
      }
    });
  }

  // Soma todos valores da lista
  var quantidadeSum = quantidadeConcat.reduce((a, b) => ({quantidade: a.quantidade + b.quantidade}));

  // Retorna apenas a quantidade total
  return quantidadeSum.quantidade;
}

function convertResponseToGridData(dados) {
  // Clona os dados para não alterar o objeto principal
  var dadosClone = JSON.parse(JSON.stringify(dados));

  // Retorna a soma dos valores das series para cada agrupamento
  return dadosClone.map(item => ({
      nome: item.nome,
      valorTotal: (item.series.reduce((a, b) => ({valor: a.valor + b.valor})).valor).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    }));
}

import { Button, LoadPanel } from 'devextreme-react';
import { alert } from 'devextreme/ui/dialog';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Checkbox, Checkitem } from '../../../../components/formulario/Formulario';
import { selectNovaOs, useNovaOs } from '../../../../redux/slices/consignadoComodato/novaOsSlice';
import { selectConsignadoComodatoSharedData } from '../../../../redux/slices/consignadoComodato/SharedData.slice';
import { ComodatoService } from '../../../../services/con-comodato/Comodato.service';
import { ConfiguracoesGeraisService } from '../../../../services/configuracoesGerais/ConfiguracoesGerais.service';
import valorFreteNaLocacao from '../totais/valorFreteNaLocacao';
import valorFreteVendaDoConsignado from '../totais/valorFreteVendaDoConsignado';
import { ApiFaturarVTexService } from '../usercases/ApiFaturarVTex.service';
import { ApiRecuperarOsService } from './../../consignado-comodato-lista/usercases/ApiRecuperarOs.service';
import { ApiRecuperarDanfeService } from './../usercases/ApiRecuperarDanfe.service';
import styles from './faturamento.module.scss';
import { FaturamentoLoteModal } from './faturamentoLoteModal';
import { RetornoComodato } from './retornoComodato';
import { RetornoConsignado } from './retornoConsignado';
import { RetornoLocacao } from './retornoLocacao';
import { FaturarPedidoService } from './usercases/faturarPedido.service';
import { normalizeToBoolean } from '../../../../shared/utils/Utils';
import { DownloadReciboLocacaoComodato } from '../../../../services/Relatorios/DownloadReciboLocacaoComodato';

export function Faturamento() {
  const { values } = useSelector(selectNovaOs);
  const dispatch = useDispatch();
  const [messageLoadin, setMessageLoadin] = useState('');
  const [searching, setSearching] = useState(false);
  const { changeValue } = useNovaOs();
  const [selectedPedidos, setSelectedPedidos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalStatus, setModalStatus] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false); // Novo estado para controlar o processamento
  const [enviarNPS, setEnviarNPS] = useState(true);
  const [showEnviarNPS, setShowEnviarNPS] = useState(false);
  const [possuiPermissaoNPS, setPossuiPermissaoNPS] = useState(false);
  const [showRetornoComodato, setShowRetornoComodato] = useState(false);
  const [showRetornoLocacao, setShowRetornoLocacao] = useState(false);
  const [showRetornoConsignado, setShowRetornoConsignado] = useState(false);
  const [busyReciboLocacao, setBusyReciboLocacao] = useState(false);
  const [checkboxVisible, setCheckboxVisible] = useState(false);
  const parametro575 = useSelector(selectConsignadoComodatoSharedData).parametro575;
  const [paramUtilizaModeloReciboLocacao, setParamUtilizaModeloReciboLocacao]= React.useState(null);
  const parametro638 = useSelector(selectConsignadoComodatoSharedData).parametro638;

  useEffect(() => {
    if (paramUtilizaModeloReciboLocacao !== null) return;
    if (!parametro638) return;
    console.log('parametro638', normalizeToBoolean(parametro638.valor));
    setParamUtilizaModeloReciboLocacao(normalizeToBoolean(parametro638.valor));
  }, [paramUtilizaModeloReciboLocacao, parametro638]);

  // Função para verificar se o botão de retorno de Comodato deve ser exibido
  const exibeBotaoComodato = useCallback(() => {
    if (values.pedidos) {
      const pedidoAnterioresRetornoComodato = values.pedidos.filter(pedido => pedido.tipoPedido.id === 5);
      const pedidoComodato = ((values || {}).pedidos || []).find(pedido => pedido.tipoPedido.id === 3);

      if (pedidoAnterioresRetornoComodato && pedidoComodato) {
        // Calcula total de itens devolvidos
        let totalItensDevolvidos = 0;

        pedidoAnterioresRetornoComodato.forEach(pedido => {
          pedido.itens.forEach(item => {
            totalItensDevolvidos += item.quantidade;
          });
        });

        const totalItensComodatados = pedidoComodato.itens ? pedidoComodato.itens.reduce((total, item) => total + item.quantidade, 0) : 0;

        return totalItensDevolvidos < totalItensComodatados;
      }
    }
    return true;
  }, [values]);

  // Função para verificar se o botão de retorno de Locação deve ser exibido
  const exibeBotaoLocacao = useCallback(() => {
    if (values.pedidos) {
      const pedidoAnterioresRetornoLocacao = values.pedidos.filter(pedido => pedido.tipoPedido.id === 9);
      const pedidoLocacao = ((values || {}).pedidos || []).find(pedido => pedido.tipoPedido.id === 8);

      if (pedidoAnterioresRetornoLocacao && pedidoLocacao) {
        // Calcula total de itens devolvidos
        let totalItensDevolvidos = 0;

        pedidoAnterioresRetornoLocacao.forEach(pedido => {
          pedido.itens.forEach(item => {
            totalItensDevolvidos += item.quantidade;
          });
        });

        const totalItensLocacoes = pedidoLocacao.itens ? pedidoLocacao.itens.reduce((total, item) => total + item.quantidade, 0) : 0;

        return totalItensDevolvidos < totalItensLocacoes;
      }
    }
    return true;
  }, [values]);

  // Função para faturar pedidos da VTEX
  const faturarVetex = useCallback(async () => {
    try {
      setSearching(true);
      await new ApiFaturarVTexService().execute(values.id);
      setSearching(false);
      document.getElementById('btnAtualizarPedido').click();
    } catch (error) {
      setSearching(false);
      alert(`Houve um problema ao sincronizar o pedido com a plataforma VTEX:.<br />${error.message}`, 'Ops!');
    }
  }, [values, setSearching]);

  // Função para reemitir uma NF-e
  const reemitirNFe = useCallback(
    async p => {
      try {
        setSearching(true);
        await ComodatoService.getInstance().emitirNFe(p.ecfCod, p.veDoCod, p.veDoCodIdeMov);
        setSearching(false);
        document.getElementById('btnAtualizarPedido').click();
      } catch (error) {
        setSearching(false);
        alert(`Houve um problema ao tentar reemitir uma NF-e:.<br />${error.message}`, 'Ops!');
      }
    },
    [setSearching],
  );
  // Função para reemitir uma NF-e
  const enviarNps = useCallback(async () => {
    try {
      setSearching(true);
      setMessageLoadin('Enviando NPS...');
      await ComodatoService.getInstance().enviarNps(values.osId);
      setSearching(false);
      Swal.fire('NPS enviado com sucesso!', 'Sucesso', 'success');
    } catch (error) {
      setSearching(false);
      Swal.fire('Houve um problema ao tentar enviar o NPS:.<br />', error.message, 'error');
    }
  }, [setSearching, values.osId]);

  // Função para verificar se o botão de retorno de Consignado deve ser exibido
  const exibeBotaoConsignado = useCallback(() => {
    if (!values.pedidos) return false;
    const pedidoRetornoConsignado = ((values || {}).pedidos || []).find(pedido => pedido.tipoPedido.id === 2);
    const retornoConsignado = ((values || {}).pedidos || []).find(pedido => pedido.tipoPedido.id === 4);
    const vendaConsignada = ((values || {}).pedidos || []).find(pedido => pedido.tipoPedido.id === 6);
    return !!(pedidoRetornoConsignado && pedidoRetornoConsignado.situacao !== 'PENDENTE' && !retornoConsignado && !vendaConsignada);
  }, [values]);

  // Efeito para determinar se os botões de retorno devem ser exibidos
  useEffect(() => {
    setShowRetornoComodato(exibeBotaoComodato());
    setShowRetornoConsignado(exibeBotaoConsignado());
    setShowRetornoLocacao(exibeBotaoLocacao());
  }, [values, exibeBotaoComodato, exibeBotaoConsignado, exibeBotaoLocacao]);

  const toggleSelectAll = useCallback(() => {
    if (selectedPedidos.length === values.pedidos.length) {
      setSelectedPedidos([]);
    } else {
      setSelectedPedidos(values.pedidos.map(pedido => pedido.pedidoId));
    }
  }, [values.pedidos, selectedPedidos]);

  const toggleCheckbox = useCallback(
    pedidoId => {
      if (selectedPedidos.includes(pedidoId)) {
        setSelectedPedidos(selectedPedidos.filter(id => id !== pedidoId));
      } else {
        setSelectedPedidos([...selectedPedidos, pedidoId]);
      }
    },
    [selectedPedidos],
  );

  const faturarTodos = useCallback(async () => {
    setShowModal(true);
    setIsProcessing(true); // Inicia o processamento
    setModalStatus([]);

    for (const pedidoId of selectedPedidos) {
      try {
        const pedido = values.pedidos.find(pedido => pedido.pedidoId === pedidoId);

        // Validação de métodos de pagamento
        if (pedido.tipoPedido.id === 1 || pedido.tipoPedido.id === 6 || pedido.tipoPedido.id === 8) {
          if (pedido.pagamentos.length === 0) {
            setModalStatus(prevStatus => [
              ...prevStatus,
              { pedidoId, status: `Erro: O pedido não possui forma de pagamento cadastrada. Por favor, revise os pagamentos antes de faturá-lo.` },
            ]);
            continue; // Pula para o próximo pedido
          }

          const valorLiquidoPedido = calcularValorLiquidoPedido(pedido, values);
          const totalPagamentos = calcularTotalPagamentos(pedido);

          if (valorLiquidoPedido !== totalPagamentos) {
            setModalStatus(prevStatus => [
              ...prevStatus,
              { pedidoId, status: `Erro: O valor total dos pagamentos é diferente do valor total do pedido. Por favor, revise os pagamentos.` },
            ]);
            continue; // Pula para o próximo pedido
          }
        }

        // Processa o faturamento do pedido
        const response = await new FaturarPedidoService().execute(pedidoId, enviarNPS);
        const { tipoPedido, nfe } = response.data.pedidos.find(p => p.pedidoId === pedidoId);
        setModalStatus(prevStatus => [...prevStatus, { pedidoId, status: 'Sucesso', tipoPedido, nfe }]);
        dispatch(changeValue({ fieldName: 'pedidos', value: response.data.pedidos }));
        dispatch(changeValue({ fieldName: 'status', value: response.data.status }));
      } catch (error) {
        setModalStatus(prevStatus => [...prevStatus, { pedidoId, status: `Erro: ${error.message}` }]);
      }
    }

    setIsProcessing(false); // Finaliza o processamento
    alert('Processo de faturamento concluído!', 'Faturamento');
  }, [selectedPedidos, enviarNPS, dispatch, changeValue, values]);

  // Função auxiliar para calcular o valor líquido do pedido
  const calcularValorLiquidoPedido = (pedido, values) => {
    if (pedido.tipoPedido.id === 1) {
      return Math.round((pedido.totalProdutos + values.valorFrete + pedido.acrescimo - pedido.desconto) * 100) / 100;
    } else if (pedido.tipoPedido.id === 6) {
      const freteVendaConsignada = valorFreteVendaDoConsignado(values, pedido);
      return Math.round((pedido.totalProdutos - pedido.desconto + pedido.acrescimo + freteVendaConsignada) * 100) / 100;
    } else if (pedido.tipoPedido.id === 8) {
      return Math.round(((pedido.totalAtivos || 0) + (pedido.totalProdutos || 0) - pedido.desconto + pedido.acrescimo + valorFreteNaLocacao(values)) * 100) / 100;
    }
    return 0;
  };

  // Função auxiliar para calcular o total de pagamentos
  const calcularTotalPagamentos = pedido => {
    return Math.round(pedido.pagamentos.reduce((acumulador, pagamento) => acumulador + pagamento.valorPagamento, 0) * 100) / 100;
  };

  const faturarPedido = useCallback(
    async (pedidoId, idx) => {
      try {
        if ((values.clienteAlternativo || {}).resolvido === false) {
          alert('Por favor, revise os dados do cliente antes de faturar o pedido.', 'Ops!');
          return;
        }
        setSearching(true);
        setMessageLoadin('Faturando o pedido...');

        const pedido = ((values || {}).pedidos || []).find(pedido => pedido.pedidoId === pedidoId);

        // Validação de pagamento para diferentes tipos de pedidos
        if (pedido.tipoPedido.id === 1 || pedido.tipoPedido.id === 6 || pedido.tipoPedido.id === 8) {
          if (pedido.pagamentos.length === 0) {
            setSearching(false);
            return alert('O pedido não possui forma de pagamento cadastrada. Por favor, revise os pagamentos antes de faturá-lo.', 'Ops!');
          }

          const valorLiquidoPedido = calcularValorLiquidoPedido(pedido, values);
          const totalPagamentos = calcularTotalPagamentos(pedido);

          if (valorLiquidoPedido !== totalPagamentos) {
            setSearching(false);
            return alert('O valor total dos pagamentos é diferente do valor total do pedido. Por favor, revise os pagamentos.', 'Ops!');
          }
        }

        const response = await new FaturarPedidoService().execute(pedidoId, enviarNPS);
        if (response && response.data) {
          dispatch(changeValue({ fieldName: 'pedidos', value: response.data.pedidos }));
          dispatch(changeValue({ fieldName: 'status', value: response.data.status }));
        }
        setSearching(false);
        alert('Faturamento realizado com sucesso!', 'Faturamento');
      } catch (error) {
        alert(error.message, 'Falha ao faturar o Pedido', 'Ops!');
        setSearching(false);
      }
    },
    [dispatch, changeValue, values, enviarNPS],
  );

  const imprimirDanfe = useCallback(async chave => {
    try {
      const data = await new ApiRecuperarDanfeService().execute(chave);
      let objBuilder = '';
      objBuilder += '<object width="100%" height="100%" data="data:application/pdf;base64,';
      objBuilder += data;
      objBuilder += '" type="application/pdf" class="internal">';
      objBuilder += '<embed src="data:application/pdf;base64,';
      objBuilder += data;
      objBuilder += '" type="application/pdf" />';
      objBuilder += '</object>';
      const win = window.open('#', '_blank');
      const title = 'PDF NFE';
      win.document.write('<html><head><title>' + title + '</title></head>');
      win.document.write('<body style="margin-top: 0px">');
      win.document.write(objBuilder);
      win.document.write('</body></html>');
    } catch (error) {
      alert(error.message, 'Falha ao faturar o Pedido', 'Ops!');
      setSearching(false);
    }
  }, []);

  const onClickReciboLocacao = useCallback( async e => {
      try {
        setBusyReciboLocacao(true);
        const download = new DownloadReciboLocacaoComodato();
        await download.execute(values.osId, values.numeroOs);
        setBusyReciboLocacao(false);
      } catch (e) {
        setBusyReciboLocacao(false);
        Swal.fire('Erro', e.message, 'error');
      }
    },
    [values.numeroOs, values.osId],
  );
  const atualizaPedido = useCallback(async () => {
    if (values.pedidos) {
      try {
        setSearching(true);
        const data = await new ApiRecuperarOsService().execute(values.id);
        if (data) {
          dispatch(changeValue({ fieldName: 'pedidos', value: data.pedidos }));
          dispatch(changeValue({ fieldName: 'status', value: data.status }));
        }
        setSearching(false);
      } catch (error) {
        setSearching(false);
        alert(`Erro ao atualizar pedido<br />${error.message}`, 'Ops!');
      }
    }
    return false;
  }, [values, dispatch, changeValue]);

  useEffect(() => {
    setCheckboxVisible(values.pedidos.some(pedido => pedido.situacao === 'PENDENTE'));
  }, [values.pedidos]);

  useEffect(() => {
    sessionStorage.setItem('envioNps', enviarNPS);
  }, [enviarNPS]);

  useEffect(() => {
    const pedidoVtex = true;
    if (pedidoVtex && parametro575 && parametro575.valor === '1') {
      setShowEnviarNPS(true);
      ConfiguracoesGeraisService.getInstance()
        .byId(568)
        .then(data => {
          setEnviarNPS(data.valor.length > 0);
        });
    } else {
      setShowEnviarNPS(false);
    }
  }, [parametro575]);

  return (
    <>
      <LoadPanel shadingColor="rgba(0,0,0,0.4)" position={{ of: '#page' }} visible={searching} showIndicator={true} shading={true} message={messageLoadin} showPane={true} />
      <Card.Title className="">
        <Row>
          <Col>
            <h4 className="">Faturamento</h4>
          </Col>
          <Col>
            <Button className="float-right mb-3" id="btnAtualizarPedido" onClick={atualizaPedido} text="ATUALIZAR" />
            {checkboxVisible && (
              <Button className="float-right mr-1 btn-primary" id="btnFaturamentoLote" onClick={faturarTodos} disabled={selectedPedidos.length === 0} text="FATURAR TODOS" />
            )}
          </Col>
        </Row>
      </Card.Title>
      <Table striped bordered hover className={`${styles.table}`}>
        <thead>
          <tr>
            {checkboxVisible && (
              <th className="text-center">
                <input type="checkbox" onChange={toggleSelectAll} checked={selectedPedidos.length === values.pedidos.length} />
              </th>
            )}
            <th className="text-center">Tipo</th>
            <th className="text-center">Data</th>
            <th className="text-center">Número da Nota</th>
            {((values || {}).pedidos || []).find(x => x.vtexOrderId) && <th className="text-center">VTex</th>}
            <th className="text-center">Data de Emissão</th>
            <th className="text-center">Status da Nota</th>
            <th className="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          {(values.pedidos || []).map((pedido, index) => (
            <tr key={pedido.pedidoId} style={{ verticalAlign: 'middle' }}>
              {checkboxVisible && (
                <td className="text-center">
                  <input type="checkbox" onChange={() => toggleCheckbox(pedido.pedidoId)} checked={selectedPedidos.includes(pedido.pedidoId)} />
                </td>
              )}
              <td>{pedido.tipoPedido.nome}</td>
              <td>{moment(pedido.dataPedido).format('DD/MM/YYYY HH:mm', { locale: 'pt-BR' })}</td>
              <td>{(pedido.nfe || {}).numeroNfe || '---'}</td>
              {((values || {}).pedidos || []).find(x => x.vtexOrderId) && <td>{pedido.vtexOrderId || '---'}</td>}
              {pedido.dataEmissao && (
                <td>
                  {new Date(pedido.dataEmissao)
                    .getDate()
                    .toString()
                    .padStart(2, '0')}
                  /{(new Date(pedido.dataEmissao).getMonth() + 1).toString().padStart(2, '0')}/{new Date(pedido.dataEmissao).getFullYear()}&nbsp;
                  {new Date(pedido.dataEmissao).getHours()}:{new Date(pedido.dataEmissao).getMinutes()}
                </td>
              )}
              {!pedido.dataEmissao && <td>---</td>}
              <td>{pedido.statusNota || 'N/A'}</td>
              <td className="text-right">
                {(pedido.nfe || {}).chave && pedido.nfe.danfeGerada && (
                  <Button icon="pdffile" onClick={() => imprimirDanfe((pedido.nfe || {}).chave)} stylingMode="outlined" hint="BAIXAR PDF" type="primary" />
                )}
                {(!pedido.faturado || pedido.id < 0) && pedido.situacao && pedido.situacao === 'PENDENTE' && (
                  <Button icon="money" type="success" stylingMode="contained" text="FATURAR" onClick={() => faturarPedido(pedido.pedidoId, index)} />
                )}
                {(pedido.id > 0 && paramUtilizaModeloReciboLocacao && pedido.tipoPedido.id === 8) && (
                   <Button icon="print" className="float-right mr-1 btn-primary" type="success" stylingMode="contained" text={`${busyReciboLocacao ? "GERANDO..." : "RECIBO LOCAÇÃO"}`} onClick={onClickReciboLocacao} />
                )}
                {pedido.id > 0 && pedido.statusNfId && pedido.statusNfId === 4 && (
                  <Button icon="money" type="info" stylingMode="contained" text="REFATURAR" onClick={() => faturarPedido(pedido.pedidoId, index)} />
                )}
                {pedido.faturado && pedido.tipoPedido.id !== 1 && pedido.tipoPedido.id !== 2 && pedido.situacao !== 'PENDENTE' && <Button type="danger" text="DEVOLVER VENDA" />}
                {pedido.situacao && pedido.situacao !== 'PENDENTE' && pedido.tipoPedido.id === 3 && showRetornoComodato && (
                  <RetornoComodato
                    pedido={pedido}
                    onUpdated={() => {
                      document.getElementById('btnAtualizarPedido').click();
                    }}
                    />
                  )}
                  {pedido.situacao && pedido.situacao !== 'PENDENTE' && pedido.tipoPedido.id === 8 && showRetornoLocacao && pedido.statusNota === 'Autorizado o uso da NF-e' && (
                    <RetornoLocacao pedido={pedido} />
                  )}
                  {showRetornoConsignado && pedido.tipoPedido.id === 2 && <RetornoConsignado pedido={pedido} />}
                  {!pedido.vtexInvoiced &&
                    pedido.vtexOrderId &&
                    [1, 8].includes(pedido.tipoPedido.id) &&
                    (pedido.nfe || {}).codigoRetorno === 100 &&
                    pedido.situacao !== 'PENDENTE' && <Button type="primary" onClick={faturarVetex} text="FATURAR NA VTEX" />}
                  {![0, 100, 101].includes((pedido.nfe || {}).codigoRetorno || 0) && pedido.statusNota && pedido.situacao !== 'PENDENTE' && (
                    <Button type="secondary" onClick={() => reemitirNFe(pedido)} text="REEMITIR NF-e" />
                  )}
                  </td>
                </tr>
                ))}
              </tbody>
              </Table>
              {(values.dataUltimoEnvioNfVtex) && (
                <div style={{ textAlign: 'right', fontWeight: 'bold', color: 'black' }}>
                  NF Enviada para a VTEX em : {moment(values.dataUltimoEnvioNfVtex).format('DD/MM/YYYY HH:mm')}
                </div>
              )}
              <FaturamentoLoteModal
              show={showModal}
              onClose={() => setShowModal(false)}
              modalStatus={modalStatus}
              isProcessing={isProcessing} // Passa o estado de processamento para o modal
      />
      { showEnviarNPS && (
        <div className="d-flex align-items-end">
          <Checkbox>
            <Checkitem
              label={`Enviar Pesquisa de Satisfação (NPS)`}
              checked={enviarNPS}
              disabled={!possuiPermissaoNPS}
              onChange={e => setEnviarNPS(e.target.checked)}
              name="envioNps"
            />
          </Checkbox>
          <span className="mb-2" style={{ left: '330px', position: 'absolute', color: 'red' }}>
            {values.cliente.contato.email}
            {values.dataUltimoEnvioNps && ` |    Último envio em : ${moment(values.dataUltimoEnvioNps).format('DD/MM/YYYY HH:mm')}`}
          </span>
          {!(values.pedidos.find(x => x.situacao === 'PENDENTE')) && <Button className="mb-1 ml-3" type="primary" onClick={enviarNps} text="REENVIAR NPS" />}
        </div>
      )}
    </>
  );
}

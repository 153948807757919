import React, { useEffect, useState } from 'react';
import {
  SectionContainer,
  SectionContent
} from '../../../../components/section/Content';
import { Button } from 'react-bootstrap';
import { ReactComponent as RaioxLogo } from '../../../../../assets/images/linx/logo_raiox.svg';
import RaioxQrcodeAndroid from '../../../../../assets/images/postopop/raiox-qrcode-android.png';
import RaioxQrcodeIos from '../../../../../assets/images/postopop/raiox-qrcode-ios.png';
import styles from './RaioXAdesao.module.scss';
import RaioXModalAdesao from '../RaioXModal/RaioXModalAdesao';

function VideoRaioX() {
  return (
    <div className={styles.videoResponsive}>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/Wum8LbOvuI0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
}

function openGuiaPassosRaioX() {
  const url = 'https://oferta.raiox.app/primeirospassos';
  window.open(url, '_blank').focus();
}

function StatusAdesaoRaioX({ isAdesaoConcluida, setShowModalAdesao, statusDetalhado, erroDetalhado1, erroDetalhado2 }) {
  var title = isAdesaoConcluida
    ? 'Status da adesão: Pendente'
    : 'Adesão ao Raio-X';

   if (erroDetalhado1 !== '' || erroDetalhado2 !== '')
  {
    title = 'Status da adesão: erro';
  }
  
  return (
    <div className={styles.textoContainer}>
      <h1>{title}</h1>
      <h2>{statusDetalhado}</h2>
      <h3>{erroDetalhado1}</h3>
      <h3>{erroDetalhado2}</h3>
      {isAdesaoConcluida ? (
        <CredenciamentoPendente />
      ) : (
        <AdesaoPendente setShowModalAdesao={setShowModalAdesao} />
      )}
    </div>
  );
}

function AdesaoPendente({ setShowModalAdesao }) {
  return (
    <>
      <p className="text-justify">
        Faça agora mesmo a adesão ao Raio-X sem custos! Otimize seu tempo,
        estruture o crescimento do seu negócio e acompanhe todas as suas vendas
        feitas em maquininhas de cartão, vale alimentação e refeição de maneira
        mais fácil.
      </p>
      <Button variant="primary" onClick={setShowModalAdesao}>
        REALIZAR A ADESÃO
      </Button>
    </>
  );
}

function AppRaioxAndroid() {
  return (
    <div className={styles.linkAppContent}>
      <a
        href="https://play.google.com/store/apps/details?id=br.com.equals&amp;hl=pt_BR"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="https://s3-sa-east-1.amazonaws.com/static-content.equals.com.br/email-raiox/botoes/btnGooglePlayColorido.png"
          height="35"
          style={{ maxWidth: '120px', margin: '15px 5px 0px 5px' }}
          alt="c94acda0-d8a8-41ad-b2d2-763e2c0e946a.png"
        />
      </a>
      <img src={RaioxQrcodeAndroid} height="150" alt="" />
    </div>
  );
}

function AppRaioxIos() {
  return (
    <div className={styles.linkAppContent}>
      <a
        href="https://apps.apple.com/br/app/raio-x-equals/id884555052"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="https://s3-sa-east-1.amazonaws.com/static-content.equals.com.br/email-raiox/botoes/btnAppleStorePreto.png"
          height="35"
          style={{ maxWidth: '120px', margin: '15px 5px 0px 5px' }}
          alt="6f17f8a4-0e88-4240-8ac0-673c6b372b2b.png"
        />
      </a>
      <img src={RaioxQrcodeIos} height="150" alt="" />
    </div>
  );
}

function CredenciamentoPendente() {
  return (
    <div className={styles.credenciamentoPendenteContainer}>
      <p className="text-justify">
        Falta pouco para você ter acesso aos dashboards do Raio-X! Conclua seu
        cadastro seguindo os passos abaixo para poder visualizar os indicadores
        por aqui.
      </p>
      <hr />
      <strong>Veja como é simples: </strong>
      <span className="text-justify">
        <strong>1.</strong> Clique em um dos botões para baixar o aplicativo ou
        abra via QRCode.
      </span>
      <div className={styles.linkAppContainer}>
        <AppRaioxAndroid />
        <AppRaioxIos />
      </div>
      <span className="text-justify">
        <strong>2.</strong> Acesse o seu e-mail para saber como cadastrar sua
        senha.
      </span>
      <span className="text-justify">
        <strong>3.</strong> Cadastre as adquirentes no aplicativo.
      </span>
      <span className="text-justify">
        <strong>4.</strong> Volte aqui para acessar o dashboard com os
        indicadores das suas vendas.
      </span>
      <hr />
      <strong style={{ marginBottom: '5px' }}>
        Dúvidas? Confira o guia de primeiros passos:{' '}
      </strong>{' '}
      <Button variant="primary" onClick={openGuiaPassosRaioX}>
        GUIA DO RAIO-X
      </Button>
    </div>
  );
}

export default function RaioXAdesao({
  isAdesaoConcluida,
  setIsAdesaoConcluida, 
  statusDetalhado, 
  erroDetalhado1,
  erroDetalhado2
}) {
  const [showModalAdesao, setShowModalAdesao] = useState(false);
  const [isAdesaoConcluidaViaModal, setIsAdesaoConcluidaViaModal] = useState(
    false
  );

  useEffect(() => {
    if (isAdesaoConcluidaViaModal) {
      setIsAdesaoConcluida(true);
    }
  }, [isAdesaoConcluidaViaModal, setIsAdesaoConcluida]);

  return (
    <>
      <SectionContainer>
        <SectionContent>
          <div className={styles.logoContainer}>
            <RaioxLogo />
          </div>
          <br />
          <div className={styles.contentContainer}>
            <div className={styles.textoContainer}>
              <h1>O que é o Raio-X?</h1>
              <p className="text-justify">
                {' '}
                O Raio-X é uma ferramenta gratuita para atender empresas que
                precisam de um controle prático e rápido das suas vendas com
                cartões e vouchers. Acompanhe vendas e recebimentos, compare
                taxas, recupere o dinheiro do seu negócio de cobranças indevidas
                e receba informações para entender o perfil de seus clientes e
                melhorar estratégias de venda. Tudo isso na mesma plataforma!{' '}
              </p>
              <VideoRaioX />
            </div>
            <div className={styles.separador} />
            <StatusAdesaoRaioX
              isAdesaoConcluida={isAdesaoConcluida}
              setShowModalAdesao={setShowModalAdesao}
              statusDetalhado={statusDetalhado}
              erroDetalhado1={erroDetalhado1}
              erroDetalhado2={erroDetalhado2}
            />
          </div>
        </SectionContent>
      </SectionContainer>
      {showModalAdesao && (
        <RaioXModalAdesao
          setShowModalAdesao={setShowModalAdesao}
          setIsAdesaoConcluidaViaModal={setIsAdesaoConcluidaViaModal}
        />
      )}
    </>
  );
}
